<template>
  <transition
    name="component-fade"
    :duration="{ enter: 0, leave: 500 }"
    @after-leave="afterLeave"
  >
    <loading-layout
      v-if="isLoadingShown"
      key="loading-layout"
      :is-loaded="isRouteLoaded"
      @animation-end="handleAnimationEnd"
    />
  </transition>

  <component
    :is="currentLayout"
    key="dynamic-layout"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { RouteName } from '@/constants';
import { eventHub } from '@/helpers/eventHub';
import { useTheme } from '@/hooks/useTheme';
import BaseLayout from '@/layouts/Base';
import EmptyLayout from '@/layouts/Empty';
import LoadingLayout from '@/layouts/Loading';
import { useProfileStore } from '@/store';

import type { CurrentLayout, AppInstance } from './types';

export default defineComponent({
  name: 'App',

  components: {
    LoadingLayout
  },

  setup(): AppInstance {
    useTheme();

    const profile = useProfileStore();
    const router = useRouter();
    const route = useRoute();

    const isLoadingShown = ref<boolean>(true);

    const isRouteLoaded = computed<boolean>(() => Boolean(route.name));

    const currentLayout = computed<CurrentLayout>(() => {
      if (!isRouteLoaded.value) return null;

      switch (route.meta.layout) {
        case 'dynamic':
          return profile.isUserExist ? BaseLayout : EmptyLayout;

        case 'empty':
          return EmptyLayout;

        default:
          return BaseLayout;
      }
    });

    document.documentElement.classList.add('is-loading');

    const afterLeave = (): void => {
      document.documentElement.classList.remove('is-loading');
      document.documentElement.classList.add('is-loaded');
    };

    const handleAnimationEnd = (): void => {
      isLoadingShown.value = false;
    };

    const handleLogoutEvent = (query?: Record<string, string>): void => {
      profile.logout();
      router.push({ name: RouteName.SIGNIN, query });
    };

    onMounted(() => {
      eventHub.on('logout', handleLogoutEvent);
    });

    onUnmounted(() => {
      eventHub.off('logout', handleLogoutEvent);
    });

    return {
      isLoadingShown,
      isRouteLoaded,
      currentLayout,
      handleAnimationEnd,
      afterLeave
    };
  }
});
</script>

<style lang="scss" scoped>
.component-fade {
  &-leave {
    &-active {
      transition: opacity 1s ease;
    }

    &-to {
      opacity: 0;
    }
  }
}

:deep(.q-form-item_is-required:not(.q-form-item_is-no-asterisk)) {
  .q-form-item__label::after {
    position: relative;
    text-shadow:
      var(--color-tertiary-gray-lighter) -1px -1px,
      var(--color-tertiary-gray-lighter) -1px 0,
      var(--color-tertiary-gray-lighter) -1px 1px,
      var(--color-tertiary-gray-lighter) 0 -1px,
      var(--color-tertiary-gray-lighter) 0 1px,
      var(--color-tertiary-gray-lighter) 1px -1px,
      var(--color-tertiary-gray-lighter) 1px 0,
      var(--color-tertiary-gray-lighter) 1px 1px;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
:root {
  --color-primary: var(--color-primary-blue);
  --color-rgb-primary: 65 98 240;
  --gradient-primary: linear-gradient(
    225deg,
    var(--color-primary-blue) 0%,
    var(--color-primary-blue-aqua) 100%
  );
  --color-indigo: #4263eb;
  --color-highlight: var(--color-brand-400);
  --panel-shadow: 1px 1px 3px 0 rgb(var(--color-rgb-blue) / 40%);
  --base-regular-96: rgb(var(--color-base-regular-rgb) / 96%);
  --base-regular-64: rgb(var(--color-base-regular-rgb) / 64%);
  --base-regular-32: rgb(var(--color-base-regular-rgb) / 32%);
  --base-regular-16: rgb(var(--color-base-regular-rgb) / 16%);
  --base-regular-8: rgb(var(--color-base-regular-rgb) / 8%);
}

html {
  &.is-loading {
    overflow: hidden;
  }

  &.is-dragging {
    user-select: none;
  }
}

img {
  max-width: 100%;
}

.a-table {
  &-t {
    padding-bottom: 16px;
  }

  &-t-body-row {
    contain: content;
  }
}
</style>
