import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const CartPage = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "cart-page" */ '@/pages/CartPage');

export const cartRoutes: RouteRecordRaw = {
  name: RouteName.CART,
  path: '/cart',
  component: CartPage,
  meta: {
    breadcrumb: 'Корзина'
  }
};
