import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const Support = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "support-page" */ '@/pages/Support');

export const supportRoutes: RouteRecordRaw = {
  path: '/support',
  component: Support,
  name: RouteName.SUPPORT,
  meta: {
    breadcrumb: 'Поддержка'
  }
};
