<template>
  <div class="page-wrap">
    <div class="page-wrap__header">
      <div class="a-page-wrap__header-inner">
        <div class="a-page-wrap__breadcrumbs-n-status">
          <a-breadcrumbs :route="breadcrumbsRoute">
            <template
              v-if="$slots.customHeading"
              #heading
            >
              <slot name="customHeading" />
            </template>
          </a-breadcrumbs>
        </div>
      </div>
    </div>

    <div class="page-wrap__body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue';
import { useRoute } from 'vue-router';

import type { RouteItem, PageWrapProps, PageWrapInstance } from './types';

export default defineComponent({
  name: 'PageWrap',

  props: {
    customRoutes: {
      type: Array as PropType<PageWrapProps['customRoutes']>,
      default: () => []
    }
  },

  setup(props: PageWrapProps): PageWrapInstance {
    const route = useRoute();

    const matchedRoutes = computed<RouteItem[]>(() =>
      route.matched.map(({ name, path, meta }) => ({ name, path, meta }))
    );

    const breadcrumbsRoute = computed<RouteItem[]>(() =>
      props.customRoutes.length ? props.customRoutes : matchedRoutes.value
    );

    return {
      breadcrumbsRoute
    };
  }
});
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: 32px 32px 0;

  &__body {
    flex: 1;
    padding-bottom: 80px;
  }
}
</style>
