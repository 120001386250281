import {
  browserTracingIntegration,
  init,
  replayIntegration
} from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import { SENTRY_DSN } from '@/constants';

const setupSentry = (app: App<Element>, router: Router): void => {
  const environment = import.meta.env.VITE_SENTRY_ENV;
  if (!environment || typeof environment !== 'string') return;

  init({
    app,
    environment,
    release: `gd-vts-ui@${APP_VERSION}`,
    dsn: SENTRY_DSN,
    integrations: [browserTracingIntegration({ router }), replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost'],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export { setupSentry };
