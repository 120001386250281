import { useStorage } from '@vueuse/core';
import { ref, computed, watch, type ComputedRef } from 'vue';

import { LocalStoreKey } from '@/constants';

export enum ThemeKey {
  NIGHT = 'a-theme-night',
  DAY = 'a-theme-day'
}

export const useTheme = (): {
  isThemeNight: ComputedRef<boolean>;
  isThemeDay: ComputedRef<boolean>;
  toggleTheme: () => void;
} => {
  const theme = ref<ThemeKey>(ThemeKey.DAY);
  const savedTheme = useStorage<ThemeKey>(LocalStoreKey.THEME, ThemeKey.DAY);

  const isThemeNight = computed<boolean>(() => theme.value === ThemeKey.NIGHT);
  const isThemeDay = computed<boolean>(() => theme.value === ThemeKey.DAY);

  if (Object.values(ThemeKey).includes(savedTheme.value)) {
    theme.value = savedTheme.value;
  } else if (window.matchMedia?.('(prefers-color-scheme: dark)').matches) {
    theme.value = ThemeKey.NIGHT;
  } else {
    theme.value = ThemeKey.DAY;
  }

  watch(
    theme,
    (currentTheme, oldTheme) => {
      const html = document.documentElement;

      html.classList.add(currentTheme);
      if (oldTheme) html.classList.remove(oldTheme);

      localStorage.setItem(LocalStoreKey.THEME, currentTheme);
    },
    { immediate: true }
  );

  const toggleTheme = (): void => {
    if (savedTheme.value === ThemeKey.DAY) {
      theme.value = ThemeKey.NIGHT;
    } else {
      theme.value = ThemeKey.DAY;
    }
    savedTheme.value = theme.value;
  };

  return {
    isThemeNight,
    isThemeDay,
    toggleTheme
  };
};
