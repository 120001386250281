import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const SearchByPalette = (): Promise<RouteComponent> =>
  import(
    /* webpackChunkName: "search-by-palette-page" */ '@/pages/SearchByPalette'
  );

export const searchByPaletteRoutes: RouteRecordRaw = {
  name: RouteName.SEARCH_BY_PALETTE,
  path: '/search-by-palette',
  component: SearchByPalette,
  meta: {
    breadcrumb: 'Поиск по палитре'
  }
};
