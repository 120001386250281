import { useNotify } from '@altai/core';

import { getErrorResponseData } from '@/helpers/getErrorResponseData';

export const showRequestErrors = (
  e: unknown,
  params: { defaultText: string }
): void => {
  const notify = useNotify();
  const errorData = getErrorResponseData(e);
  if (!errorData) {
    notify.error({ content: params.defaultText });
  } else {
    (Array.isArray(errorData)
      ? errorData
      : Object.entries(errorData).map(item => item.join(': '))
    ).forEach(error => {
      notify.error({ content: error });
    });
  }
};
