export const NOTIFY_DURATION = 5000;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 100;
export const DATE_FORMAT = 'dd.MM.yyyy';
export const SENTRY_DSN =
  'https://7297e9242dc030d0dbd790327680fbce@sentry.gpmdata.tech/46';
export const PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
export const YANDEX_METRIC_ID = 90744941;
export const FILE_LOADER_TYPE_KEY = 'file_loader_type';
export const FILE_LOADER_DATA_KEY = 'file_loader_data';
export const CART_MAX_ITEM_COUNT = 100;
export const HELP_EMAIL = 'vts_help@gpmdata.ru';
export const DEFAULT_LIST_LIMIT = 1000;
export const EMPTY_VALUE_TEXT = '\u2014';
export const NBSP = '\u00A0';
export const REQUIRED_RULE = {
  required: true,
  message: 'Поле обязательно для заполнения',
  trigger: 'blur'
};
export const MAX_STORAGE_SIZE_BYTES = 4.5 * 1024 ** 2;

export enum LocalStoreKey {
  ACCESS_TOKEN = 'access_token',
  THEME = 'ui_theme',
  BY_TAGS_SEARCH_QUERY = 'search-by-tags-query',
  BY_PALETTE_SEARCH_QUERY = 'search-by-palette-query',
  UNIVERSAL_SEARCH_QUERY = 'universal-search-query'
}

export enum PhraseSearchMode {
  dictionary = 'dictionary',
  text = 'text'
}

export * from './RouteName';
export * from './TagsTitlesMap';
export * from './MetricGoal';
export * from './FileType';
