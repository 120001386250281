import type {
  RouteComponent,
  RouteLocationRaw,
  RouteRecordRaw
} from 'vue-router';

import { RouteName } from '@/constants';

const AdminPage = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminPage');
const AdminCommon = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminCommon');
const AdminVideoList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminVideoList');
const AdminVideoDetail = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminVideoDetail');
const AdminPersonList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminPersonList');
const AdminPersonDetail = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminPersonDetail');
const AdminPriorityList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminPriorityList');
const AdminExclusionList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminExclusionList');
const AdminDictionaryList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminDictionaryList');
const AdminSrtParamsDetail = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminSrtParamsDetail');
const AdminUserList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminUserList');
const AdminReportList = (): Promise<RouteComponent> =>
  import('@/pages/admin/AdminReportList');

export const adminRoutes: RouteRecordRaw = {
  name: RouteName.ADMIN,
  path: '/admin',
  component: AdminPage,
  meta: {
    breadcrumb: 'Админ панель'
  },
  redirect: (): RouteLocationRaw => ({ name: RouteName.ADMIN_COMMON }),
  children: [
    {
      name: RouteName.ADMIN_COMMON,
      path: '',
      component: AdminCommon
    },
    {
      path: 'video',
      meta: { breadcrumb: 'Видео' },
      children: [
        {
          name: RouteName.ADMIN_VIDEO_LIST,
          path: '',
          component: AdminVideoList
        },
        {
          name: RouteName.ADMIN_VIDEO_CREATE,
          path: 'new',
          component: AdminVideoDetail,
          meta: { breadcrumb: 'Новое видео' }
        },
        {
          name: RouteName.ADMIN_VIDEO_DETAIL,
          path: ':id',
          component: AdminVideoDetail,
          meta: { breadcrumb: 'Редактирование видео' }
        }
      ]
    },
    {
      path: 'person',
      meta: { breadcrumb: 'Персоны' },
      children: [
        {
          name: RouteName.ADMIN_PERSON_LIST,
          path: '',
          component: AdminPersonList
        },
        {
          name: RouteName.ADMIN_PERSON_CREATE,
          path: 'new',
          component: AdminPersonDetail,
          meta: { breadcrumb: 'Новая персона' }
        },
        {
          name: RouteName.ADMIN_PERSON_DETAIL,
          path: ':id',
          component: AdminPersonDetail,
          meta: { breadcrumb: 'Редактирование персоны' }
        }
      ]
    },
    {
      path: 'exclusion',
      meta: { breadcrumb: 'Запреты на обработку' },
      children: [
        {
          name: RouteName.ADMIN_EXCLUSION_LIST,
          path: '',
          component: AdminExclusionList
        },
        {
          name: RouteName.ADMIN_EXCLUSION_CREATE,
          path: 'new',
          component: AdminExclusionList
        },
        {
          name: RouteName.ADMIN_EXCLUSION_DETAIL,
          path: ':id',
          component: AdminExclusionList
        }
      ]
    },
    {
      path: 'priority',
      meta: { breadcrumb: 'Приоритеты' },
      children: [
        {
          name: RouteName.ADMIN_PRIORITY_LIST,
          path: '',
          component: AdminPriorityList
        },
        {
          name: RouteName.ADMIN_PRIORITY_CREATE,
          path: 'new',
          component: AdminPriorityList
        },
        {
          name: RouteName.ADMIN_PRIORITY_DETAIL,
          path: ':id',
          component: AdminPriorityList
        }
      ]
    },
    {
      path: 'dictionary',
      meta: { breadcrumb: 'Словари' },
      children: [
        {
          name: RouteName.ADMIN_DICTIONARY_LIST,
          path: '',
          component: AdminDictionaryList
        },
        {
          name: RouteName.ADMIN_DICTIONARY_CREATE,
          path: 'new',
          component: AdminDictionaryList
        },
        {
          name: RouteName.ADMIN_DICTIONARY_DETAIL,
          path: ':id',
          component: AdminDictionaryList
        }
      ]
    },
    {
      path: 'user',
      meta: { breadcrumb: 'Пользователи' },
      children: [
        {
          name: RouteName.ADMIN_USER_LIST,
          path: '',
          component: AdminUserList
        },
        {
          name: RouteName.ADMIN_USER_CREATE,
          path: 'new',
          component: AdminUserList
        },
        {
          name: RouteName.ADMIN_USER_DETAIL,
          path: ':id',
          component: AdminUserList
        }
      ]
    },
    {
      path: 'subtitles',
      meta: { breadcrumb: 'Настройки субтитров' },
      children: [
        {
          name: RouteName.ADMIN_SRT_PARAMS_DETAIL,
          path: '',
          component: AdminSrtParamsDetail
        }
      ]
    },
    {
      path: 'reports',
      meta: { breadcrumb: 'Выгрузить отчет' },
      children: [
        {
          name: RouteName.ADMIN_REPORT_CREATE,
          path: '',
          component: AdminReportList
        }
      ]
    }
  ]
};
