import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const SearchByTags = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "search-by-tags-page" */ '@/pages/SearchByTags');

export const searchByTagsRoutes: RouteRecordRaw = {
  name: RouteName.SEARCH_BY_TAGS,
  path: '/search-by-tags',
  component: SearchByTags,
  meta: {
    breadcrumb: 'Поиск по тегам'
  }
};
