import type { RequestData } from '../types';

export class RequestError extends Error {
  requestData: RequestData;

  responseData?: unknown;

  status: number;

  constructor(requestData: RequestData, responseData: unknown, status: number) {
    super(`request error ${requestData.path}`);
    this.requestData = requestData;
    this.responseData = responseData;
    this.status = status;
  }
}
