import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const Profile = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "profile-page" */ '@/pages/Profile');

export const profileRoutes: RouteRecordRaw = {
  path: '/profile',
  component: Profile,
  name: RouteName.PROFILE,
  meta: {
    breadcrumb: 'Настройки профиля'
  }
};
