import { useNotify } from '@altai/core';
import Keycloak from 'keycloak-js';
import { useRouter } from 'vue-router';

import { RouteName } from '@/constants';
import { useProfileStore } from '@/store';

interface UseKeycloak {
  keycloak: Keycloak;
  isAuthenticated: boolean;
}

const MIN_TOKEN_VALIDITY = 30;

export const useKeycloak = async (): Promise<UseKeycloak> => {
  const notify = useNotify();
  const router = useRouter();
  const profile = useProfileStore();

  const keycloak = new Keycloak({
    url: import.meta.env.VITE_KEYCLOAK_ENDPOINT,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
    clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID
  });

  keycloak.onTokenExpired = async (): Promise<void> => {
    try {
      await keycloak.updateToken(MIN_TOKEN_VALIDITY);

      if (keycloak.token && keycloak.refreshToken) {
        profile.setState({ keycloak });
      }
    } catch (error) {
      notify.error({ content: `Failed to refresh token: ${error}` });
      profile.logout();
      router.push({ name: RouteName.SIGNIN });
    }
  };

  const isAuthenticated = await keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: false
  });

  return { keycloak, isAuthenticated };
};
