import { isObject } from 'lodash-es';

import { eventHub } from '@/helpers/eventHub';
import { useProfileStore } from '@/store';

import { handleError, RequestError } from './errors';
import type { FileData, RequestData } from './types';

export const BASE_URL = `${import.meta.env.VITE_API_ENDPOINT}/api`;

const baseRequest = async (requestData: RequestData): Promise<Response> => {
  const { method, path, contentType, url = BASE_URL, signal } = requestData;
  const { body } = requestData;

  if (!url) return Promise.reject();

  const profile = useProfileStore();
  const token = profile.keycloak?.token ?? '';
  const options: RequestInit = { method, signal };
  options.headers = {};

  options.headers.authorization = `Bearer ${token}`;

  /**
   * Don't add Content-Type header when sending FormData - let browser handle it
   * @see https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
   */
  if (contentType === 'json') {
    options.headers['content-type'] = 'application/json';
  }

  if (body) {
    options.body =
      contentType !== 'formData' && isObject(body)
        ? JSON.stringify(body)
        : (body as BodyInit);
  }

  const requestUrl = path?.length ? `${url}/${path}` : url;

  let response;

  try {
    response = await fetch(requestUrl, options);
  } catch (e) {
    if (!(e instanceof DOMException) || e.name !== 'AbortError') {
      handleError({ message: 'Произошла ошибка при выполнении запроса' });
    }
    return Promise.reject();
  }

  if ([500, 502, 503, 504].includes(response.status)) {
    handleError({ code: response.status });

    const error = new RequestError(requestData, {}, response.status);
    return Promise.reject(error);
  }

  if (response.status === 404) {
    handleError({
      message: 'Указанный ресурс недоступен. Попробуйте повторить запрос позже'
    });
  }

  if (response.status === 403) {
    handleError({
      message: 'Не хватает прав доступа. Обратитесь в техническую поддержку'
    });
  }

  if (response.status === 401) {
    eventHub.emit('logout', {
      redirect: window.location.pathname
    });
  }

  return response;
};

export const request = async <T>(requestData: RequestData): Promise<T> => {
  const response = await baseRequest(requestData);

  let responseData;

  if (response.status === 204) {
    responseData = await response.text();
  } else {
    try {
      responseData =
        requestData.responseType === 'blob'
          ? await response.blob()
          : await response.json();
    } catch (error) {
      // Если ответ не является JSON, использовать текст ответа
      responseData = await response.text();
    }
  }

  if (response.ok) {
    return responseData;
  }

  const error = new RequestError(requestData, responseData, response.status);
  return Promise.reject(error);
};

export const requestFile = async (
  requestData: RequestData
): Promise<FileData> => {
  const response = await baseRequest(requestData);
  const data = await response.blob();

  if (!response.ok) {
    const error = new RequestError(requestData, data, response.status);
    return Promise.reject(error);
  }

  const disposition = response.headers.get('content-disposition');
  const filename =
    disposition?.split('=').pop()?.replace(/"/g, '') || 'download';

  return { data, filename };
};

export default {
  BASE_URL,
  request,
  requestFile
};
