import type { RouteRecordRaw, RouteComponent } from 'vue-router';

import { RouteName } from '@/constants';

const UniversalSearch = (): Promise<RouteComponent> =>
  import(
    /* webpackChunkName: "universal-search-page" */ '@/pages/UniversalSearch'
  );

export const universalSearchRoutes: RouteRecordRaw = {
  name: RouteName.UNIVERSAL_SEARCH,
  path: '/universal-search',
  component: UniversalSearch,
  meta: {
    breadcrumb: 'Поиск по запросу'
  }
};
