export enum RouteName {
  HOME = 'HOME',

  PROFILE = 'PROFILE',
  SUPPORT = 'SUPPORT',

  SEARCH_BY_TAGS = 'SEARCH_BY_TAGS',
  SEARCH_BY_PALETTE = 'SEARCH_BY_PALETTE',
  UNIVERSAL_SEARCH = 'UNIVERSAL_SEARCH',
  CART = 'CART',

  ADMIN = 'ADMIN',
  ADMIN_COMMON = 'ADMIN_COMMON',
  ADMIN_VIDEO_LIST = 'ADMIN_VIDEO_LIST',
  ADMIN_VIDEO_DETAIL = 'ADMIN_VIDEO_DETAIL',
  ADMIN_VIDEO_CREATE = 'ADMIN_VIDEO_CREATE',
  ADMIN_PERSON_LIST = 'ADMIN_PERSON_LIST',
  ADMIN_PERSON_DETAIL = 'ADMIN_PERSON_DETAIL',
  ADMIN_PERSON_CREATE = 'ADMIN_PERSON_CREATE',
  ADMIN_PRIORITY_LIST = 'ADMIN_PRIORITY_LIST',
  ADMIN_PRIORITY_DETAIL = 'ADMIN_PRIORITY_DETAIL',
  ADMIN_PRIORITY_CREATE = 'ADMIN_PRIORITY_CREATE',
  ADMIN_EXCLUSION_LIST = 'ADMIN_EXCLUSION_LIST',
  ADMIN_EXCLUSION_DETAIL = 'ADMIN_EXCLUSION_DETAIL',
  ADMIN_EXCLUSION_CREATE = 'ADMIN_EXCLUSION_CREATE',
  ADMIN_DICTIONARY_LIST = 'ADMIN_DICTIONARY_LIST',
  ADMIN_DICTIONARY_DETAIL = 'ADMIN_DICTIONARY_DETAIL',
  ADMIN_DICTIONARY_CREATE = 'ADMIN_DICTIONARY_CREATE',
  ADMIN_SRT_PARAMS_LIST = 'ADMIN_SRT_PARAMS_LIST',
  ADMIN_SRT_PARAMS_DETAIL = 'ADMIN_SRT_PARAMS_DETAIL',
  ADMIN_SRT_PARAMS_CREATE = 'ADMIN_SRT_PARAMS_CREATE',
  ADMIN_USER_LIST = 'ADMIN_USER_LIST',
  ADMIN_USER_DETAIL = 'ADMIN_USER_DETAIL',
  ADMIN_USER_CREATE = 'ADMIN_USER_CREATE',
  ADMIN_REPORT_CREATE = 'ADMIN_REPORT_CREATE',

  SIGNIN = 'SIGNIN',

  SOMETHING_WRONG = 'SOMETHING_WRONG',

  FILE_LOADER = 'FILE_LOADER',

  SHORT_PROJECTS = 'SHORT_PROJECTS',
  SHORT_PROJECTS_LIST = 'SHORT_PROJECTS_LIST',
  SHORT_PROJECTS_CREATE = 'SHORT_PROJECTS_CREATE',
  SHORT_PROJECTS_DETAIL = 'SHORT_PROJECTS_DETAIL',
  SHORT_PROJECTS_DETAIL_EDIT = 'SHORT_PROJECTS_DETAIL_EDIT'
}
