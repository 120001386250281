<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      fill-rule="evenodd"
      d="M2 6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 7v1.5h2V13H4Zm2-2H4V9.5h2V11Zm2 7h8V6H8v12ZM6 6H4v1.5h2V6Zm12 0v1.5h2V6h-2Zm2 3.5h-2V11h2V9.5Zm0 3.5h-2v1.5h2V13Zm0 3.5h-2V18h2v-1.5ZM6 18v-1.5H4V18h2Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconVideoTapeOutlined'
});
</script>
