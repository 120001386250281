// @todo: удалить после создания a-slider range
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// style override
import './style.scss';
import { createApp } from 'vue';

import App from './components/App';
import { setupAppComponents, setupSentry } from './hooks';
import { setupRouter } from './router';
import { setupStore } from './store';

const bootstrap = async (): Promise<void> => {
  const app = createApp(App);

  app.use(ElementPlus);
  setupStore(app);
  setupAppComponents(app);

  const router = setupRouter(app);
  setupSentry(app, router);
  await router.isReady();

  app.mount('#app');
};

bootstrap();
