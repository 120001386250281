<template>
  <div class="base-layout">
    <a-sidebar
      v-model:collapsed="isCollapsed"
      class="base-layout__sidebar"
      name="VTS"
      :logo="ProductLogo"
      :menu-items="menuItems"
    />

    <div class="base-layout__content">
      <router-view />
    </div>

    <a-float-bar class="base-layout__float-bar">
      <a-tooltip
        v-for="(item, index) in floatBarItems"
        :key="index"
        :content="item.label"
      >
        <a-button
          :theme="AButtonTheme.ghostSecondary"
          :size="AButtonSize.small"
          :icon="item.icon"
          @click="item.action"
        />
      </a-tooltip>
    </a-float-bar>
  </div>
</template>

<script lang="ts">
import {
  AButtonSize,
  AButtonTheme,
  IconLogoutOutlined,
  IconMoonFilled,
  IconMoonOutlined,
  IconUserSingleOutlined
} from '@altai/core';
import { type Component, computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import { ProductLogo } from '@/components/icons';
import { RouteName } from '@/constants';
import { useTheme } from '@/hooks/useTheme';
import { useProfileStore } from '@/store';

import { useSidebarData } from './hooks';
import type { FloatBarItem, LayoutBaseInstance } from './types';

export default defineComponent({
  name: 'LayoutBase',

  setup(): LayoutBaseInstance {
    const router = useRouter();
    const profile = useProfileStore();
    const { menuItems } = useSidebarData();
    const { isThemeNight, toggleTheme } = useTheme();

    const isCollapsed = ref<boolean>(false);

    const themeToggleIcon = computed<Component>(() => {
      return isThemeNight.value ? IconMoonFilled : IconMoonOutlined;
    });

    const handleThemeToggle = (): void => {
      toggleTheme();
    };

    const handleProfileOpen = (): void => {
      router.push({ name: RouteName.PROFILE });
    };

    const handleLogoutClick = (): void => {
      profile.logout();
      router.push({ name: RouteName.SIGNIN });
    };

    const floatBarItems = computed<FloatBarItem[]>(() => [
      {
        label: 'Переключить тему',
        icon: themeToggleIcon.value,
        action: handleThemeToggle
      },
      {
        label: 'Профиль',
        icon: IconUserSingleOutlined,
        action: handleProfileOpen
      },
      {
        label: 'Выход',
        icon: IconLogoutOutlined,
        action: handleLogoutClick
      }
    ]);

    return {
      AButtonSize,
      AButtonTheme,
      ProductLogo,
      isCollapsed,
      menuItems,
      floatBarItems
    };
  }
});
</script>

<style lang="scss" scoped>
.base-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  min-width: 1280px;
  min-height: 100%;

  &__sidebar {
    position: sticky;
    top: 0;
    height: 100vh;

    &:not(.a-sidebar_is_collapsed) {
      width: 250px;
    }

    :deep(.a-sidebar-item__text) {
      width: auto;
      white-space: nowrap;
    }
  }

  &__float-bar {
    position: fixed;
    top: 32px;
    right: 32px;
    z-index: 99;
    height: 40px;
    background-color: var(--background-higher);
    border-radius: 8px;
    box-shadow: var(--box-shadow-400);
  }

  &__content,
  &__inner {
    height: 100%;
  }

  &__content {
    width: 100%;
    min-width: 100%;
    opacity: 0;
    transition:
      transform 0.5s ease 0.15s,
      opacity 0.5s ease 0.15s;
    transform: translateY(50px);

    html.is-loaded & {
      opacity: 1;
      transform: none;
    }
  }
}
</style>
