import type {
  RouteRecordRaw,
  RouteLocationRaw,
  RouteComponent
} from 'vue-router';

import { RouteName } from '@/constants';

const ShortsPage = (): Promise<RouteComponent> => import('@/pages/Shorts');
const ShortListPage = (): Promise<RouteComponent> =>
  import('@/pages/Shorts/ShortList');
const ShortCreatePage = (): Promise<RouteComponent> =>
  import('@/pages/Shorts/ShortCreate');
const ShortDetailPage = (): Promise<RouteComponent> =>
  import('@/pages/Shorts/ShortDetail');
const ShortDetailEditPage = (): Promise<RouteComponent> =>
  import('@/pages/Shorts/ShortDetailEdit');

export const shortsRoutes: RouteRecordRaw = {
  path: '/shorts',
  component: ShortsPage,
  name: RouteName.SHORT_PROJECTS,
  meta: {
    breadcrumb: 'Shorts'
  },
  redirect: (): RouteLocationRaw => ({ name: RouteName.SHORT_PROJECTS_LIST }),
  children: [
    {
      name: RouteName.SHORT_PROJECTS_LIST,
      path: '',
      component: ShortListPage
    },
    {
      name: RouteName.SHORT_PROJECTS_CREATE,
      path: 'new',
      component: ShortCreatePage,
      meta: {
        breadcrumb: 'Создать shorts'
      }
    },
    {
      name: RouteName.SHORT_PROJECTS_DETAIL,
      path: ':id',
      component: ShortDetailPage,
      meta: {
        breadcrumb: 'Редактировать проект'
      }
    },
    {
      name: RouteName.SHORT_PROJECTS_DETAIL_EDIT,
      path: ':id/edit',
      component: ShortDetailEditPage,
      meta: {
        breadcrumb: 'Редактировать shorts'
      }
    }
  ]
};
