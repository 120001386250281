<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="32"
    fill="currentColor"
    viewBox="0 0 36 32"
  >
    <path
      fill-rule="evenodd"
      d="M3.1 5.5H18L14.9 0H0l3.1 5.5Zm5.69 10.11 4.19-7.45H4.59l4.2 7.45ZM27.24 0l-4.7 8.38L17.84 0h9.4Zm.95 13.9L35.99 0H30.2l-7.75 13.9h5.74Zm-1.47 2.6-4.53 8.07-4.55-8.07h9.08ZM18.01 32l2.94-5.24-7.83-12.97-2.68 4.77 3.62 6.42L18 32Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductLogo'
});
</script>
