import { useNotify } from '@altai/core';
import { throttle } from 'lodash-es';

import { NOTIFY_DURATION } from '@/constants';

const notify = useNotify();

export const handleError = throttle(
  ({ code, message }) => {
    const content =
      message ?? `Ошибка сервера: ${code}. Попробуйте повторить запрос позже.`;

    notify.error({
      content,
      options: { duration: NOTIFY_DURATION }
    });
  },
  5000,
  { leading: true }
);
