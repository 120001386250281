import type { ASidebarMenuItem } from '@altai/core';
import {
  IconCartOutlined,
  IconDisplayOutlined,
  IconEditOutlined,
  IconKeyOutlined,
  IconPalleteOutlined,
  IconSearchOutlined,
  IconSupportFilled,
  IconUserSingleFilled,
  SidebarMenuItemType,
  useMessageBox
} from '@altai/core';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { IconVideoTapeOutlined } from '@/components/icons';
import { TutorialModal } from '@/components/TutorialModal';
import { RouteName } from '@/constants';
import { useCartStore, useProfileStore } from '@/store';

import type { UseSidebarDataResult } from './types';

export const useSidebarData = (): UseSidebarDataResult => {
  const route = useRoute();
  const profile = useProfileStore();
  const cart = useCartStore();
  const messageBox = useMessageBox();

  const userName = computed<string>(() => {
    const { firstName, lastName } = profile.user || {};
    return `${firstName || ''} ${lastName || ''}`.trim() || 'Пользователь';
  });

  const isAdmin = computed<boolean>(() => Boolean(profile?.user?.isAdmin));

  const cartItemName = computed<string>(() => {
    if (!cart.videosLength) return 'Корзина';
    return `Корзина (${cart.videosLength})`;
  });

  const getSidebarLinkData = (
    routeName: RouteName
  ): { active: boolean; props: { to: { name: RouteName } } } => ({
    active: route.matched.some(({ name }) => name === routeName),
    props: { to: { name: routeName } }
  });

  const menuItems = computed<ASidebarMenuItem[]>(() => [
    {
      type: SidebarMenuItemType.link,
      title: userName.value,
      icon: IconUserSingleFilled,
      ...getSidebarLinkData(RouteName.PROFILE)
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Поддержка',
      icon: IconSupportFilled,
      ...getSidebarLinkData(RouteName.SUPPORT)
    },
    ...(isAdmin.value
      ? [
          {
            type: SidebarMenuItemType.link,
            title: 'Админка',
            icon: IconKeyOutlined,
            ...getSidebarLinkData(RouteName.ADMIN)
          } as ASidebarMenuItem
        ]
      : []),
    {
      type: SidebarMenuItemType.divider
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Shorts',
      icon: IconVideoTapeOutlined,
      ...getSidebarLinkData(RouteName.SHORT_PROJECTS)
    },
    {
      type: SidebarMenuItemType.divider
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Универсальный поиск',
      icon: IconEditOutlined,
      ...getSidebarLinkData(RouteName.UNIVERSAL_SEARCH)
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Поиск по тегам',
      icon: IconSearchOutlined,
      ...getSidebarLinkData(RouteName.SEARCH_BY_TAGS)
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Поиск по палитре',
      icon: IconPalleteOutlined,
      ...getSidebarLinkData(RouteName.SEARCH_BY_PALETTE)
    },
    {
      type: SidebarMenuItemType.divider
    },
    {
      type: SidebarMenuItemType.link,
      title: cartItemName.value,
      icon: IconCartOutlined,
      ...getSidebarLinkData(RouteName.CART)
    },
    {
      type: SidebarMenuItemType.dividerAuto
    },
    {
      type: SidebarMenuItemType.link,
      title: 'Видео-инструкция',
      icon: IconDisplayOutlined,
      active: false,
      tag: 'button',
      props: {
        type: 'button',
        async onClick(): Promise<void> {
          try {
            await messageBox(
              {
                title: 'Видео-инструкция',
                component: TutorialModal
              },
              {
                wrapStyle: { maxWidth: '80vw' }
              }
            );
          } catch {
            // do nothing
          }
        }
      }
    }
  ]);

  return {
    menuItems
  };
};
