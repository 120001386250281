import { useMessageBox } from '@altai/core';
import { union, unionBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { nextTick } from 'vue';

import { MAX_STORAGE_SIZE_BYTES } from '@/constants';
import { useUserStorage } from '@/hooks';

import type { Timecode, VideoDataExtended } from '#/types';

import type { CartState } from './types';

export const useCartStore = defineStore({
  id: 'cart',

  state: (): CartState => ({
    videos: useUserStorage('cart', {})
  }),

  getters: {
    videosLength(): number {
      return Object.values(this.videos).reduce((acc, videos) => {
        return acc + videos.length;
      }, 0);
    },

    videosList(): VideoDataExtended[] {
      return Object.values(this.videos).reduce(
        (acc, videos) => [...acc, ...videos],
        []
      );
    },

    getVideoTimecodes(): (video: VideoDataExtended) => Timecode[] {
      return video => {
        const currentVideo = this.videos[video.filterKey]?.find(
          ({ id }) => id === video.id
        );
        return currentVideo?.timecodes ?? [];
      };
    }
  },

  actions: {
    clearCart(): void {
      this.videos = {};
    },

    async addVideo(items: VideoDataExtended[]): Promise<void> {
      if (!items.length) return;

      const storageSize = new Blob(Object.values(localStorage)).size;
      const dataSize = new Blob(items.map(item => JSON.stringify(item))).size;

      if (MAX_STORAGE_SIZE_BYTES > storageSize + dataSize) {
        const filtersKey = JSON.stringify(items[0].filters);
        const existingVideos = this.videos[filtersKey] || [];
        const timecodesByID = [...existingVideos, ...items].reduce(
          (acc, item) => {
            const { id, timecodes } = item;
            return acc.set(
              id,
              union(acc.get(id) ?? [], timecodes).sort(([a], [b]) => a - b)
            );
          },
          new Map() as Map<number, Timecode[]>
        );
        this.videos[filtersKey] = unionBy(existingVideos, items, 'id').map(
          video => {
            const newTimecodes = timecodesByID.get(video.id) ?? [];
            return {
              ...video,
              timecodes: unionBy(
                video.timecodes,
                newTimecodes,
                item => `${item}`
              ).sort(([a], [b]) => a - b)
            };
          }
        );
      } else {
        const messageBox = useMessageBox();

        try {
          await messageBox({
            title: 'Внимание',
            message:
              'Заканчивается доступное место для хранения записей корзины. Добавление новых записей может привести к ошибке.',
            confirmButtonText: 'Очистить корзину и добавить',
            cancelButtonText: 'Отмена'
          });

          this.clearCart();
          nextTick(() => {
            this.addVideo(items);
          });
        } catch {
          // do nothing
        }
      }
    },

    removeVideo(removeId: number, filtersKey: string): void {
      this.videos[filtersKey] = this.videos[filtersKey].filter(
        ({ id }) => id !== removeId
      );
      if (!this.videosList.length) this.clearCart();
    },

    setVideoTimecodes(video: VideoDataExtended, timecodes: Timecode[]): void {
      if (!timecodes.length) {
        this.removeVideo(video.id, video.filterKey);
        return;
      }

      const videosByFilter = this.videos[video.filterKey] || [];
      this.videos[video.filterKey] = unionBy(
        videosByFilter,
        [{ ...video }], // копия объекта, чтобы не изменять объект по ссылке
        'id'
      );

      const selectedVideo = this.videos[video.filterKey].find(
        ({ id }) => id === video.id
      );
      if (!selectedVideo) return;
      selectedVideo.timecodes = timecodes.sort(([a], [b]) => a - b);
    }
  }
});

export * from './types';
