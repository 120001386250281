import { type Nillable, useProgressIndicator } from '@altai/core';
import type { RouteLocationRaw, Router } from 'vue-router';

import { RouteName } from '@/constants';
import { useKeycloak } from '@/hooks';
import { useProfileStore } from '@/store';

const checkPaths = (name: Nillable<string>): boolean => {
  if (!name) return false;

  return [RouteName.HOME, RouteName.SIGNIN].includes(name as RouteName);
};

const signInPageParams: RouteLocationRaw = { name: RouteName.SIGNIN };
const homePageParams: RouteLocationRaw = { name: RouteName.HOME };

export const createPermissionGuard = (router: Router): void => {
  const profile = useProfileStore();
  const progressBar = useProgressIndicator();

  router.beforeEach(async ({ name, fullPath, redirectedFrom, matched }) => {
    progressBar?.start();
    if (redirectedFrom) progressBar?.done();

    const isSignIn = name === RouteName.SIGNIN;
    const isAdminRoute = matched.some(route => route.name === RouteName.ADMIN);

    if (!isSignIn && name !== RouteName.HOME) {
      signInPageParams.query = { redirect: fullPath };
    }

    if (checkPaths(name as RouteName)) return true;

    if (profile.user) {
      if (isSignIn) {
        return homePageParams;
      }

      if (isAdminRoute && !profile.user?.isAdmin) {
        return homePageParams;
      }

      return true;
    }

    const { isAuthenticated, keycloak } = await useKeycloak();
    if (isAuthenticated) {
      profile.setState({ keycloak });
      const { username } = await keycloak.loadUserProfile();
      if (username) {
        await profile.getCurrentUser(username);
      } else {
        await profile.logout();

        return signInPageParams;
      }

      return true;
    }

    return signInPageParams;
  });

  router.afterEach(async () => {
    progressBar?.done();
  });
};
