<template>
  <div class="tutorial-modal">
    <video
      v-if="tutorialSrc"
      class="tutorial-modal__video"
      :src="tutorialSrc"
      controls
    />
    <div v-else>Видео-инструкция не найдена</div>
  </div>
</template>

<script lang="ts">
import type { Nullable } from '@altai/core';
import { defineComponent, onUnmounted, ref } from 'vue';

import { showRequestErrors } from '@/helpers/showRequestErrors';
import { getGuides } from '@/services/guides';

import type { TutorialModalInstance } from './types';

export default defineComponent({
  name: 'TutorialModal',

  setup(): TutorialModalInstance {
    const tutorialSrc = ref<Nullable<string>>(null);

    let abortController: Nullable<AbortController> = null;

    const getTutorialData = async (): Promise<void> => {
      abortController?.abort();
      abortController = new AbortController();

      try {
        const { results } = await getGuides({
          signal: abortController.signal,
          params: { search: 'tutorial' }
        });

        tutorialSrc.value = results[0]?.attachment ?? null;
      } catch (e) {
        showRequestErrors(e, { defaultText: 'Не удалось получить данные' });
      }
    };

    getTutorialData();

    onUnmounted(() => {
      abortController?.abort();
    });

    return {
      tutorialSrc
    };
  }
});
</script>

<style lang="scss" scoped>
.tutorial-modal {
  position: relative;
  max-height: 100%;
  padding: 16px;

  &__video {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e61a0; // video bg color
    object-fit: contain;
  }
}
</style>
