import { RequestError } from '@/services/errors';

import type { Nullable } from '#/types';

export const getErrorResponseData = (e: unknown): Nullable<object> => {
  if (e instanceof RequestError && typeof e.responseData === 'object') {
    return e.responseData;
  }
  return null;
};
