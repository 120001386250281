import { isNil, isPlainObject } from 'lodash-es';

import type { Nillable } from '#/types';

import { convertKeysToSnake } from '../convertKeys';

const isEmpty = (value: unknown): boolean => {
  return (
    isNil(value) ||
    isPlainObject(value) ||
    ((Array.isArray(value) || typeof value === 'string') && !value.length)
  );
};

export const prepareQueryParams = (
  params?: Nillable<Record<string, unknown>>
): string => {
  if (!params) return '';

  const convertedParams = convertKeysToSnake(params);

  const queryParams = new URLSearchParams();

  Object.entries(convertedParams).forEach(([key, value]) => {
    if (isEmpty(value)) return;

    if (!Array.isArray(value)) {
      queryParams.append(key, String(value));
      return;
    }

    const preparedValue = value.filter(
      subValue => !isEmpty(subValue) && !Array.isArray(subValue)
    );
    if (preparedValue.length) queryParams.append(key, preparedValue.join(','));
  });

  return queryParams.toString();
};
