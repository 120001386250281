import { convertKeysToCamel } from '@/helpers/convertKeys';
import { prepareQueryParams } from '@/helpers/prepareQueryParams';
import { request } from '@/services';

import type { GetGuidesRequestArg, GetGuidesResponse } from './types';

export const getGuides = async ({
  signal,
  params
}: GetGuidesRequestArg): Promise<GetGuidesResponse> => {
  return request<GetGuidesResponse>({
    signal,
    path: `guides/?${prepareQueryParams(params)}`,
    method: 'GET',
    contentType: 'json'
  }).then(convertKeysToCamel);
};
