import { defineStore } from 'pinia';

import { recordTrexEvent } from '@/helpers/recordTrexEvent';
import { getUserByUsername } from '@/services/user';

import type { Optional } from '#/types';

import type { ProfileState } from './types';

export const useProfileStore = defineStore({
  id: 'profile',

  state: (): ProfileState => ({
    user: null,
    keycloak: null
  }),

  getters: {
    isUserExist(): boolean {
      return Boolean(this.user);
    },

    userId(): Optional<number> {
      return this.user?.id;
    },

    isUserDetached(): boolean {
      return !this.user?.customerId;
    }
  },

  actions: {
    setState({ user, keycloak }: Partial<ProfileState>): void {
      if (keycloak) this.keycloak = keycloak;
      if (user) this.user = user;
    },

    async getCurrentUser(username: string): Promise<void> {
      try {
        const user = await getUserByUsername({ params: { username } });
        this.setState({ user });
      } catch (error) {
        this.logout();

        throw error;
      }
    },

    async logout(): Promise<void> {
      recordTrexEvent({ event: 'logout' });
      await this.keycloak?.logout();
      this.$reset();
    }
  }
});

export type { ProfileState };
