import { convertKeysToCamel } from '@/helpers/convertKeys';
import { request } from '@/services';

import type { GetUserByUsernameArg, User } from './types';

export const getUserByUsername = async ({
  signal,
  params: { username }
}: GetUserByUsernameArg): Promise<User> => {
  return request<User>({
    signal,
    path: `users/${username}`,
    method: 'GET',
    contentType: 'json'
  }).then(convertKeysToCamel);
};

export type { GetUserByUsernameArg, User };
