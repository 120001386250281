import type { RouteComponent, RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/constants';

import { adminRoutes } from './admin';
import { cartRoutes } from './cart';
import { profileRoutes } from './profile';
import { searchByPaletteRoutes } from './searchByPalette';
import { searchByTagsRoutes } from './searchByTags';
import { shortsRoutes } from './shorts';
import { supportRoutes } from './support';
import { universalSearchRoutes } from './universalSearch';

const SignIn = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "public-pages" */ '@/pages/SignIn');
const SomethingWrong = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "public-pages" */ '@/pages/SomethingWrong');
const FileLoader = (): Promise<RouteComponent> =>
  import(/* webpackChunkName: "public-pages" */ '@/pages/FileLoader');

export const routes: RouteRecordRaw[] = [
  adminRoutes,
  cartRoutes,
  profileRoutes,
  searchByPaletteRoutes,
  searchByTagsRoutes,
  shortsRoutes,
  universalSearchRoutes,
  supportRoutes,

  {
    name: RouteName.HOME,
    path: '/',
    redirect: { name: RouteName.SEARCH_BY_TAGS }
  },

  {
    name: RouteName.SIGNIN,
    path: '/signin',
    component: SignIn,
    meta: { layout: 'empty' }
  },

  {
    name: RouteName.FILE_LOADER,
    path: '/file',
    component: FileLoader,
    meta: { layout: 'empty' }
  },

  {
    name: RouteName.SOMETHING_WRONG,
    path: '/:pathMatch(.*)*',
    props: true,
    component: SomethingWrong
  }
];
