import { useStorage } from '@vueuse/core';
import type { Ref } from 'vue';

import { useProfileStore } from '@/store';

export const useUserStorage = <T>(
  storageKey: string,
  defaultValue: T,
  storage = localStorage
): Ref<T> => {
  const profile = useProfileStore();

  return useStorage<T>(
    `${storageKey}:${profile.userId ?? 0}`,
    defaultValue,
    storage
  );
};
