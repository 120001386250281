<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <defs>
      <clipPath id="a">
        <path d="M0 75h150v75H0z" />
      </clipPath>
      <mask id="b">
        <path
          fill="white"
          d="M0 0h150v150H0z"
        />
        <path
          fill="rgba(254,254,254,1)"
          d="M0 0h75v75H0z"
          transform="rotate(1 75 75)"
        />
        <path
          fill="rgba(253,253,253,1)"
          d="M0 0h75v75H0z"
          transform="rotate(2 75 75)"
        />
        <path
          fill="rgba(252,252,252,1)"
          d="M0 0h75v75H0z"
          transform="rotate(3 75 75)"
        />
        <path
          fill="rgba(252,252,252,1)"
          d="M0 0h75v75H0z"
          transform="rotate(4 75 75)"
        />
        <path
          fill="rgba(251,251,251,1)"
          d="M0 0h75v75H0z"
          transform="rotate(5 75 75)"
        />
        <path
          fill="rgba(250,250,250,1)"
          d="M0 0h75v75H0z"
          transform="rotate(6 75 75)"
        />
        <path
          fill="rgba(250,250,250,1)"
          d="M0 0h75v75H0z"
          transform="rotate(7 75 75)"
        />
        <path
          fill="rgba(249,249,249,1)"
          d="M0 0h75v75H0z"
          transform="rotate(8 75 75)"
        />
        <path
          fill="rgba(248,248,248,1)"
          d="M0 0h75v75H0z"
          transform="rotate(9 75 75)"
        />
        <path
          fill="rgba(247,247,247,1)"
          d="M0 0h75v75H0z"
          transform="rotate(10 75 75)"
        />
        <path
          fill="rgba(247,247,247,1)"
          d="M0 0h75v75H0z"
          transform="rotate(11 75 75)"
        />
        <path
          fill="rgba(246,246,246,1)"
          d="M0 0h75v75H0z"
          transform="rotate(12 75 75)"
        />
        <path
          fill="rgba(245,245,245,1)"
          d="M0 0h75v75H0z"
          transform="rotate(13 75 75)"
        />
        <path
          fill="rgba(245,245,245,1)"
          d="M0 0h75v75H0z"
          transform="rotate(14 75 75)"
        />
        <path
          fill="rgba(244,244,244,1)"
          d="M0 0h75v75H0z"
          transform="rotate(15 75 75)"
        />
        <path
          fill="rgba(243,243,243,1)"
          d="M0 0h75v75H0z"
          transform="rotate(16 75 75)"
        />
        <path
          fill="rgba(242,242,242,1)"
          d="M0 0h75v75H0z"
          transform="rotate(17 75 75)"
        />
        <path
          fill="rgba(242,242,242,1)"
          d="M0 0h75v75H0z"
          transform="rotate(18 75 75)"
        />
        <path
          fill="rgba(241,241,241,1)"
          d="M0 0h75v75H0z"
          transform="rotate(19 75 75)"
        />
        <path
          fill="rgba(240,240,240,1)"
          d="M0 0h75v75H0z"
          transform="rotate(20 75 75)"
        />
        <path
          fill="rgba(240,240,240,1)"
          d="M0 0h75v75H0z"
          transform="rotate(21 75 75)"
        />
        <path
          fill="rgba(239,239,239,1)"
          d="M0 0h75v75H0z"
          transform="rotate(22 75 75)"
        />
        <path
          fill="rgba(238,238,238,1)"
          d="M0 0h75v75H0z"
          transform="rotate(23 75 75)"
        />
        <path
          fill="rgba(238,238,238,1)"
          d="M0 0h75v75H0z"
          transform="rotate(24 75 75)"
        />
        <path
          fill="rgba(237,237,237,1)"
          d="M0 0h75v75H0z"
          transform="rotate(25 75 75)"
        />
        <path
          fill="rgba(236,236,236,1)"
          d="M0 0h75v75H0z"
          transform="rotate(26 75 75)"
        />
        <path
          fill="rgba(235,235,235,1)"
          d="M0 0h75v75H0z"
          transform="rotate(27 75 75)"
        />
        <path
          fill="rgba(235,235,235,1)"
          d="M0 0h75v75H0z"
          transform="rotate(28 75 75)"
        />
        <path
          fill="rgba(234,234,234,1)"
          d="M0 0h75v75H0z"
          transform="rotate(29 75 75)"
        />
        <path
          fill="rgba(233,233,233,1)"
          d="M0 0h75v75H0z"
          transform="rotate(30 75 75)"
        />
        <path
          fill="rgba(233,233,233,1)"
          d="M0 0h75v75H0z"
          transform="rotate(31 75 75)"
        />
        <path
          fill="rgba(232,232,232,1)"
          d="M0 0h75v75H0z"
          transform="rotate(32 75 75)"
        />
        <path
          fill="rgba(231,231,231,1)"
          d="M0 0h75v75H0z"
          transform="rotate(33 75 75)"
        />
        <path
          fill="rgba(230,230,230,1)"
          d="M0 0h75v75H0z"
          transform="rotate(34 75 75)"
        />
        <path
          fill="rgba(230,230,230,1)"
          d="M0 0h75v75H0z"
          transform="rotate(35 75 75)"
        />
        <path
          fill="rgba(229,229,229,1)"
          d="M0 0h75v75H0z"
          transform="rotate(36 75 75)"
        />
        <path
          fill="rgba(228,228,228,1)"
          d="M0 0h75v75H0z"
          transform="rotate(37 75 75)"
        />
        <path
          fill="rgba(228,228,228,1)"
          d="M0 0h75v75H0z"
          transform="rotate(38 75 75)"
        />
        <path
          fill="rgba(227,227,227,1)"
          d="M0 0h75v75H0z"
          transform="rotate(39 75 75)"
        />
        <path
          fill="rgba(226,226,226,1)"
          d="M0 0h75v75H0z"
          transform="rotate(40 75 75)"
        />
        <path
          fill="rgba(225,225,225,1)"
          d="M0 0h75v75H0z"
          transform="rotate(41 75 75)"
        />
        <path
          fill="rgba(225,225,225,1)"
          d="M0 0h75v75H0z"
          transform="rotate(42 75 75)"
        />
        <path
          fill="rgba(224,224,224,1)"
          d="M0 0h75v75H0z"
          transform="rotate(43 75 75)"
        />
        <path
          fill="rgba(223,223,223,1)"
          d="M0 0h75v75H0z"
          transform="rotate(44 75 75)"
        />
        <path
          fill="rgba(223,223,223,1)"
          d="M0 0h75v75H0z"
          transform="rotate(45 75 75)"
        />
        <path
          fill="rgba(222,222,222,1)"
          d="M0 0h75v75H0z"
          transform="rotate(46 75 75)"
        />
        <path
          fill="rgba(221,221,221,1)"
          d="M0 0h75v75H0z"
          transform="rotate(47 75 75)"
        />
        <path
          fill="rgba(221,221,221,1)"
          d="M0 0h75v75H0z"
          transform="rotate(48 75 75)"
        />
        <path
          fill="rgba(220,220,220,1)"
          d="M0 0h75v75H0z"
          transform="rotate(49 75 75)"
        />
        <path
          fill="rgba(219,219,219,1)"
          d="M0 0h75v75H0z"
          transform="rotate(50 75 75)"
        />
        <path
          fill="rgba(218,218,218,1)"
          d="M0 0h75v75H0z"
          transform="rotate(51 75 75)"
        />
        <path
          fill="rgba(218,218,218,1)"
          d="M0 0h75v75H0z"
          transform="rotate(52 75 75)"
        />
        <path
          fill="rgba(217,217,217,1)"
          d="M0 0h75v75H0z"
          transform="rotate(53 75 75)"
        />
        <path
          fill="rgba(216,216,216,1)"
          d="M0 0h75v75H0z"
          transform="rotate(54 75 75)"
        />
        <path
          fill="rgba(216,216,216,1)"
          d="M0 0h75v75H0z"
          transform="rotate(55 75 75)"
        />
        <path
          fill="rgba(215,215,215,1)"
          d="M0 0h75v75H0z"
          transform="rotate(56 75 75)"
        />
        <path
          fill="rgba(214,214,214,1)"
          d="M0 0h75v75H0z"
          transform="rotate(57 75 75)"
        />
        <path
          fill="rgba(213,213,213,1)"
          d="M0 0h75v75H0z"
          transform="rotate(58 75 75)"
        />
        <path
          fill="rgba(213,213,213,1)"
          d="M0 0h75v75H0z"
          transform="rotate(59 75 75)"
        />
        <path
          fill="rgba(212,212,212,1)"
          d="M0 0h75v75H0z"
          transform="rotate(60 75 75)"
        />
        <path
          fill="rgba(211,211,211,1)"
          d="M0 0h75v75H0z"
          transform="rotate(61 75 75)"
        />
        <path
          fill="rgba(211,211,211,1)"
          d="M0 0h75v75H0z"
          transform="rotate(62 75 75)"
        />
        <path
          fill="rgba(210,210,210,1)"
          d="M0 0h75v75H0z"
          transform="rotate(63 75 75)"
        />
        <path
          fill="rgba(209,209,209,1)"
          d="M0 0h75v75H0z"
          transform="rotate(64 75 75)"
        />
        <path
          fill="rgba(208,208,208,1)"
          d="M0 0h75v75H0z"
          transform="rotate(65 75 75)"
        />
        <path
          fill="rgba(208,208,208,1)"
          d="M0 0h75v75H0z"
          transform="rotate(66 75 75)"
        />
        <path
          fill="rgba(207,207,207,1)"
          d="M0 0h75v75H0z"
          transform="rotate(67 75 75)"
        />
        <path
          fill="rgba(206,206,206,1)"
          d="M0 0h75v75H0z"
          transform="rotate(68 75 75)"
        />
        <path
          fill="rgba(206,206,206,1)"
          d="M0 0h75v75H0z"
          transform="rotate(69 75 75)"
        />
        <path
          fill="rgba(205,205,205,1)"
          d="M0 0h75v75H0z"
          transform="rotate(70 75 75)"
        />
        <path
          fill="rgba(204,204,204,1)"
          d="M0 0h75v75H0z"
          transform="rotate(71 75 75)"
        />
        <path
          fill="rgba(204,204,204,1)"
          d="M0 0h75v75H0z"
          transform="rotate(72 75 75)"
        />
        <path
          fill="rgba(203,203,203,1)"
          d="M0 0h75v75H0z"
          transform="rotate(73 75 75)"
        />
        <path
          fill="rgba(202,202,202,1)"
          d="M0 0h75v75H0z"
          transform="rotate(74 75 75)"
        />
        <path
          fill="rgba(201,201,201,1)"
          d="M0 0h75v75H0z"
          transform="rotate(75 75 75)"
        />
        <path
          fill="rgba(201,201,201,1)"
          d="M0 0h75v75H0z"
          transform="rotate(76 75 75)"
        />
        <path
          fill="rgba(200,200,200,1)"
          d="M0 0h75v75H0z"
          transform="rotate(77 75 75)"
        />
        <path
          fill="rgba(199,199,199,1)"
          d="M0 0h75v75H0z"
          transform="rotate(78 75 75)"
        />
        <path
          fill="rgba(199,199,199,1)"
          d="M0 0h75v75H0z"
          transform="rotate(79 75 75)"
        />
        <path
          fill="rgba(198,198,198,1)"
          d="M0 0h75v75H0z"
          transform="rotate(80 75 75)"
        />
        <path
          fill="rgba(197,197,197,1)"
          d="M0 0h75v75H0z"
          transform="rotate(81 75 75)"
        />
        <path
          fill="rgba(196,196,196,1)"
          d="M0 0h75v75H0z"
          transform="rotate(82 75 75)"
        />
        <path
          fill="rgba(196,196,196,1)"
          d="M0 0h75v75H0z"
          transform="rotate(83 75 75)"
        />
        <path
          fill="rgba(195,195,195,1)"
          d="M0 0h75v75H0z"
          transform="rotate(84 75 75)"
        />
        <path
          fill="rgba(194,194,194,1)"
          d="M0 0h75v75H0z"
          transform="rotate(85 75 75)"
        />
        <path
          fill="rgba(194,194,194,1)"
          d="M0 0h75v75H0z"
          transform="rotate(86 75 75)"
        />
        <path
          fill="rgba(193,193,193,1)"
          d="M0 0h75v75H0z"
          transform="rotate(87 75 75)"
        />
        <path
          fill="rgba(192,192,192,1)"
          d="M0 0h75v75H0z"
          transform="rotate(88 75 75)"
        />
        <path
          fill="rgba(191,191,191,1)"
          d="M0 0h75v75H0z"
          transform="rotate(89 75 75)"
        />
        <path
          fill="rgba(191,191,191,1)"
          d="M0 0h75v75H0z"
          transform="rotate(90 75 75)"
        />
        <path
          fill="rgba(190,190,190,1)"
          d="M0 0h75v75H0z"
          transform="rotate(91 75 75)"
        />
        <path
          fill="rgba(189,189,189,1)"
          d="M0 0h75v75H0z"
          transform="rotate(92 75 75)"
        />
        <path
          fill="rgba(189,189,189,1)"
          d="M0 0h75v75H0z"
          transform="rotate(93 75 75)"
        />
        <path
          fill="rgba(188,188,188,1)"
          d="M0 0h75v75H0z"
          transform="rotate(94 75 75)"
        />
        <path
          fill="rgba(187,187,187,1)"
          d="M0 0h75v75H0z"
          transform="rotate(95 75 75)"
        />
        <path
          fill="rgba(187,187,187,1)"
          d="M0 0h75v75H0z"
          transform="rotate(96 75 75)"
        />
        <path
          fill="rgba(186,186,186,1)"
          d="M0 0h75v75H0z"
          transform="rotate(97 75 75)"
        />
        <path
          fill="rgba(185,185,185,1)"
          d="M0 0h75v75H0z"
          transform="rotate(98 75 75)"
        />
        <path
          fill="rgba(184,184,184,1)"
          d="M0 0h75v75H0z"
          transform="rotate(99 75 75)"
        />
        <path
          fill="rgba(184,184,184,1)"
          d="M0 0h75v75H0z"
          transform="rotate(100 75 75)"
        />
        <path
          fill="rgba(183,183,183,1)"
          d="M0 0h75v75H0z"
          transform="rotate(101 75 75)"
        />
        <path
          fill="rgba(182,182,182,1)"
          d="M0 0h75v75H0z"
          transform="rotate(102 75 75)"
        />
        <path
          fill="rgba(182,182,182,1)"
          d="M0 0h75v75H0z"
          transform="rotate(103 75 75)"
        />
        <path
          fill="rgba(181,181,181,1)"
          d="M0 0h75v75H0z"
          transform="rotate(104 75 75)"
        />
        <path
          fill="rgba(180,180,180,1)"
          d="M0 0h75v75H0z"
          transform="rotate(105 75 75)"
        />
        <path
          fill="rgba(179,179,179,1)"
          d="M0 0h75v75H0z"
          transform="rotate(106 75 75)"
        />
        <path
          fill="rgba(179,179,179,1)"
          d="M0 0h75v75H0z"
          transform="rotate(107 75 75)"
        />
        <path
          fill="rgba(178,178,178,1)"
          d="M0 0h75v75H0z"
          transform="rotate(108 75 75)"
        />
        <path
          fill="rgba(177,177,177,1)"
          d="M0 0h75v75H0z"
          transform="rotate(109 75 75)"
        />
        <path
          fill="rgba(177,177,177,1)"
          d="M0 0h75v75H0z"
          transform="rotate(110 75 75)"
        />
        <path
          fill="rgba(176,176,176,1)"
          d="M0 0h75v75H0z"
          transform="rotate(111 75 75)"
        />
        <path
          fill="rgba(175,175,175,1)"
          d="M0 0h75v75H0z"
          transform="rotate(112 75 75)"
        />
        <path
          fill="rgba(174,174,174,1)"
          d="M0 0h75v75H0z"
          transform="rotate(113 75 75)"
        />
        <path
          fill="rgba(174,174,174,1)"
          d="M0 0h75v75H0z"
          transform="rotate(114 75 75)"
        />
        <path
          fill="rgba(173,173,173,1)"
          d="M0 0h75v75H0z"
          transform="rotate(115 75 75)"
        />
        <path
          fill="rgba(172,172,172,1)"
          d="M0 0h75v75H0z"
          transform="rotate(116 75 75)"
        />
        <path
          fill="rgba(172,172,172,1)"
          d="M0 0h75v75H0z"
          transform="rotate(117 75 75)"
        />
        <path
          fill="rgba(171,171,171,1)"
          d="M0 0h75v75H0z"
          transform="rotate(118 75 75)"
        />
        <path
          fill="rgba(170,170,170,1)"
          d="M0 0h75v75H0z"
          transform="rotate(119 75 75)"
        />
        <path
          fill="rgba(170,170,170,1)"
          d="M0 0h75v75H0z"
          transform="rotate(120 75 75)"
        />
        <path
          fill="rgba(169,169,169,1)"
          d="M0 0h75v75H0z"
          transform="rotate(121 75 75)"
        />
        <path
          fill="rgba(168,168,168,1)"
          d="M0 0h75v75H0z"
          transform="rotate(122 75 75)"
        />
        <path
          fill="rgba(167,167,167,1)"
          d="M0 0h75v75H0z"
          transform="rotate(123 75 75)"
        />
        <path
          fill="rgba(167,167,167,1)"
          d="M0 0h75v75H0z"
          transform="rotate(124 75 75)"
        />
        <path
          fill="rgba(166,166,166,1)"
          d="M0 0h75v75H0z"
          transform="rotate(125 75 75)"
        />
        <path
          fill="rgba(165,165,165,1)"
          d="M0 0h75v75H0z"
          transform="rotate(126 75 75)"
        />
        <path
          fill="rgba(165,165,165,1)"
          d="M0 0h75v75H0z"
          transform="rotate(127 75 75)"
        />
        <path
          fill="rgba(164,164,164,1)"
          d="M0 0h75v75H0z"
          transform="rotate(128 75 75)"
        />
        <path
          fill="rgba(163,163,163,1)"
          d="M0 0h75v75H0z"
          transform="rotate(129 75 75)"
        />
        <path
          fill="rgba(162,162,162,1)"
          d="M0 0h75v75H0z"
          transform="rotate(130 75 75)"
        />
        <path
          fill="rgba(162,162,162,1)"
          d="M0 0h75v75H0z"
          transform="rotate(131 75 75)"
        />
        <path
          fill="rgba(161,161,161,1)"
          d="M0 0h75v75H0z"
          transform="rotate(132 75 75)"
        />
        <path
          fill="rgba(160,160,160,1)"
          d="M0 0h75v75H0z"
          transform="rotate(133 75 75)"
        />
        <path
          fill="rgba(160,160,160,1)"
          d="M0 0h75v75H0z"
          transform="rotate(134 75 75)"
        />
        <path
          fill="rgba(159,159,159,1)"
          d="M0 0h75v75H0z"
          transform="rotate(135 75 75)"
        />
        <path
          fill="rgba(158,158,158,1)"
          d="M0 0h75v75H0z"
          transform="rotate(136 75 75)"
        />
        <path
          fill="rgba(157,157,157,1)"
          d="M0 0h75v75H0z"
          transform="rotate(137 75 75)"
        />
        <path
          fill="rgba(157,157,157,1)"
          d="M0 0h75v75H0z"
          transform="rotate(138 75 75)"
        />
        <path
          fill="rgba(156,156,156,1)"
          d="M0 0h75v75H0z"
          transform="rotate(139 75 75)"
        />
        <path
          fill="rgba(155,155,155,1)"
          d="M0 0h75v75H0z"
          transform="rotate(140 75 75)"
        />
        <path
          fill="rgba(155,155,155,1)"
          d="M0 0h75v75H0z"
          transform="rotate(141 75 75)"
        />
        <path
          fill="rgba(154,154,154,1)"
          d="M0 0h75v75H0z"
          transform="rotate(142 75 75)"
        />
        <path
          fill="rgba(153,153,153,1)"
          d="M0 0h75v75H0z"
          transform="rotate(143 75 75)"
        />
        <path
          fill="rgba(153,153,153,1)"
          d="M0 0h75v75H0z"
          transform="rotate(144 75 75)"
        />
        <path
          fill="rgba(152,152,152,1)"
          d="M0 0h75v75H0z"
          transform="rotate(145 75 75)"
        />
        <path
          fill="rgba(151,151,151,1)"
          d="M0 0h75v75H0z"
          transform="rotate(146 75 75)"
        />
        <path
          fill="rgba(150,150,150,1)"
          d="M0 0h75v75H0z"
          transform="rotate(147 75 75)"
        />
        <path
          fill="rgba(150,150,150,1)"
          d="M0 0h75v75H0z"
          transform="rotate(148 75 75)"
        />
        <path
          fill="rgba(149,149,149,1)"
          d="M0 0h75v75H0z"
          transform="rotate(149 75 75)"
        />
        <path
          fill="rgba(148,148,148,1)"
          d="M0 0h75v75H0z"
          transform="rotate(150 75 75)"
        />
        <path
          fill="rgba(148,148,148,1)"
          d="M0 0h75v75H0z"
          transform="rotate(151 75 75)"
        />
        <path
          fill="rgba(147,147,147,1)"
          d="M0 0h75v75H0z"
          transform="rotate(152 75 75)"
        />
        <path
          fill="rgba(146,146,146,1)"
          d="M0 0h75v75H0z"
          transform="rotate(153 75 75)"
        />
        <path
          fill="rgba(145,145,145,1)"
          d="M0 0h75v75H0z"
          transform="rotate(154 75 75)"
        />
        <path
          fill="rgba(145,145,145,1)"
          d="M0 0h75v75H0z"
          transform="rotate(155 75 75)"
        />
        <path
          fill="rgba(144,144,144,1)"
          d="M0 0h75v75H0z"
          transform="rotate(156 75 75)"
        />
        <path
          fill="rgba(143,143,143,1)"
          d="M0 0h75v75H0z"
          transform="rotate(157 75 75)"
        />
        <path
          fill="rgba(143,143,143,1)"
          d="M0 0h75v75H0z"
          transform="rotate(158 75 75)"
        />
        <path
          fill="rgba(142,142,142,1)"
          d="M0 0h75v75H0z"
          transform="rotate(159 75 75)"
        />
        <path
          fill="rgba(141,141,141,1)"
          d="M0 0h75v75H0z"
          transform="rotate(160 75 75)"
        />
        <path
          fill="rgba(140,140,140,1)"
          d="M0 0h75v75H0z"
          transform="rotate(161 75 75)"
        />
        <path
          fill="rgba(140,140,140,1)"
          d="M0 0h75v75H0z"
          transform="rotate(162 75 75)"
        />
        <path
          fill="rgba(139,139,139,1)"
          d="M0 0h75v75H0z"
          transform="rotate(163 75 75)"
        />
        <path
          fill="rgba(138,138,138,1)"
          d="M0 0h75v75H0z"
          transform="rotate(164 75 75)"
        />
        <path
          fill="rgba(138,138,138,1)"
          d="M0 0h75v75H0z"
          transform="rotate(165 75 75)"
        />
        <path
          fill="rgba(137,137,137,1)"
          d="M0 0h75v75H0z"
          transform="rotate(166 75 75)"
        />
        <path
          fill="rgba(136,136,136,1)"
          d="M0 0h75v75H0z"
          transform="rotate(167 75 75)"
        />
        <path
          fill="rgba(136,136,136,1)"
          d="M0 0h75v75H0z"
          transform="rotate(168 75 75)"
        />
        <path
          fill="rgba(135,135,135,1)"
          d="M0 0h75v75H0z"
          transform="rotate(169 75 75)"
        />
        <path
          fill="rgba(134,134,134,1)"
          d="M0 0h75v75H0z"
          transform="rotate(170 75 75)"
        />
        <path
          fill="rgba(133,133,133,1)"
          d="M0 0h75v75H0z"
          transform="rotate(171 75 75)"
        />
        <path
          fill="rgba(133,133,133,1)"
          d="M0 0h75v75H0z"
          transform="rotate(172 75 75)"
        />
        <path
          fill="rgba(132,132,132,1)"
          d="M0 0h75v75H0z"
          transform="rotate(173 75 75)"
        />
        <path
          fill="rgba(131,131,131,1)"
          d="M0 0h75v75H0z"
          transform="rotate(174 75 75)"
        />
        <path
          fill="rgba(131,131,131,1)"
          d="M0 0h75v75H0z"
          transform="rotate(175 75 75)"
        />
        <path
          fill="rgba(130,130,130,1)"
          d="M0 0h75v75H0z"
          transform="rotate(176 75 75)"
        />
        <path
          fill="rgba(129,129,129,1)"
          d="M0 0h75v75H0z"
          transform="rotate(177 75 75)"
        />
        <path
          fill="rgba(128,128,128,1)"
          d="M0 0h75v75H0z"
          transform="rotate(178 75 75)"
        />
        <path
          fill="rgba(128,128,128,1)"
          d="M0 0h75v75H0z"
          transform="rotate(179 75 75)"
        />
        <path
          fill="rgba(127,127,127,1)"
          d="M0 0h75v75H0z"
          transform="rotate(180 75 75)"
        />
        <g clip-path="url(#a)">
          <path
            fill="rgba(126,126,126,1)"
            d="M0 0h75v75H0z"
            transform="rotate(181 75 75)"
          />
          <path
            fill="rgba(126,126,126,1)"
            d="M0 0h75v75H0z"
            transform="rotate(182 75 75)"
          />
          <path
            fill="rgba(125,125,125,1)"
            d="M0 0h75v75H0z"
            transform="rotate(183 75 75)"
          />
          <path
            fill="rgba(124,124,124,1)"
            d="M0 0h75v75H0z"
            transform="rotate(184 75 75)"
          />
          <path
            fill="rgba(123,123,123,1)"
            d="M0 0h75v75H0z"
            transform="rotate(185 75 75)"
          />
          <path
            fill="rgba(123,123,123,1)"
            d="M0 0h75v75H0z"
            transform="rotate(186 75 75)"
          />
          <path
            fill="rgba(122,122,122,1)"
            d="M0 0h75v75H0z"
            transform="rotate(187 75 75)"
          />
          <path
            fill="rgba(121,121,121,1)"
            d="M0 0h75v75H0z"
            transform="rotate(188 75 75)"
          />
          <path
            fill="rgba(121,121,121,1)"
            d="M0 0h75v75H0z"
            transform="rotate(189 75 75)"
          />
          <path
            fill="rgba(120,120,120,1)"
            d="M0 0h75v75H0z"
            transform="rotate(190 75 75)"
          />
          <path
            fill="rgba(119,119,119,1)"
            d="M0 0h75v75H0z"
            transform="rotate(191 75 75)"
          />
          <path
            fill="rgba(119,119,119,1)"
            d="M0 0h75v75H0z"
            transform="rotate(192 75 75)"
          />
          <path
            fill="rgba(118,118,118,1)"
            d="M0 0h75v75H0z"
            transform="rotate(193 75 75)"
          />
          <path
            fill="rgba(117,117,117,1)"
            d="M0 0h75v75H0z"
            transform="rotate(194 75 75)"
          />
          <path
            fill="rgba(116,116,116,1)"
            d="M0 0h75v75H0z"
            transform="rotate(195 75 75)"
          />
          <path
            fill="rgba(116,116,116,1)"
            d="M0 0h75v75H0z"
            transform="rotate(196 75 75)"
          />
          <path
            fill="rgba(115,115,115,1)"
            d="M0 0h75v75H0z"
            transform="rotate(197 75 75)"
          />
          <path
            fill="rgba(114,114,114,1)"
            d="M0 0h75v75H0z"
            transform="rotate(198 75 75)"
          />
          <path
            fill="rgba(114,114,114,1)"
            d="M0 0h75v75H0z"
            transform="rotate(199 75 75)"
          />
          <path
            fill="rgba(113,113,113,1)"
            d="M0 0h75v75H0z"
            transform="rotate(200 75 75)"
          />
          <path
            fill="rgba(112,112,112,1)"
            d="M0 0h75v75H0z"
            transform="rotate(201 75 75)"
          />
          <path
            fill="rgba(111,111,111,1)"
            d="M0 0h75v75H0z"
            transform="rotate(202 75 75)"
          />
          <path
            fill="rgba(111,111,111,1)"
            d="M0 0h75v75H0z"
            transform="rotate(203 75 75)"
          />
          <path
            fill="rgba(110,110,110,1)"
            d="M0 0h75v75H0z"
            transform="rotate(204 75 75)"
          />
          <path
            fill="rgba(109,109,109,1)"
            d="M0 0h75v75H0z"
            transform="rotate(205 75 75)"
          />
          <path
            fill="rgba(109,109,109,1)"
            d="M0 0h75v75H0z"
            transform="rotate(206 75 75)"
          />
          <path
            fill="rgba(108,108,108,1)"
            d="M0 0h75v75H0z"
            transform="rotate(207 75 75)"
          />
          <path
            fill="rgba(107,107,107,1)"
            d="M0 0h75v75H0z"
            transform="rotate(208 75 75)"
          />
          <path
            fill="rgba(106,106,106,1)"
            d="M0 0h75v75H0z"
            transform="rotate(209 75 75)"
          />
          <path
            fill="rgba(106,106,106,1)"
            d="M0 0h75v75H0z"
            transform="rotate(210 75 75)"
          />
          <path
            fill="rgba(105,105,105,1)"
            d="M0 0h75v75H0z"
            transform="rotate(211 75 75)"
          />
          <path
            fill="rgba(104,104,104,1)"
            d="M0 0h75v75H0z"
            transform="rotate(212 75 75)"
          />
          <path
            fill="rgba(104,104,104,1)"
            d="M0 0h75v75H0z"
            transform="rotate(213 75 75)"
          />
          <path
            fill="rgba(103,103,103,1)"
            d="M0 0h75v75H0z"
            transform="rotate(214 75 75)"
          />
          <path
            fill="rgba(102,102,102,1)"
            d="M0 0h75v75H0z"
            transform="rotate(215 75 75)"
          />
          <path
            fill="rgba(102,102,102,1)"
            d="M0 0h75v75H0z"
            transform="rotate(216 75 75)"
          />
          <path
            fill="rgba(101,101,101,1)"
            d="M0 0h75v75H0z"
            transform="rotate(217 75 75)"
          />
          <path
            fill="rgba(100,100,100,1)"
            d="M0 0h75v75H0z"
            transform="rotate(218 75 75)"
          />
          <path
            fill="rgba(99,99,99,1)"
            d="M0 0h75v75H0z"
            transform="rotate(219 75 75)"
          />
          <path
            fill="rgba(99,99,99,1)"
            d="M0 0h75v75H0z"
            transform="rotate(220 75 75)"
          />
          <path
            fill="rgba(98,98,98,1)"
            d="M0 0h75v75H0z"
            transform="rotate(221 75 75)"
          />
          <path
            fill="rgba(97,97,97,1)"
            d="M0 0h75v75H0z"
            transform="rotate(222 75 75)"
          />
          <path
            fill="rgba(97,97,97,1)"
            d="M0 0h75v75H0z"
            transform="rotate(223 75 75)"
          />
          <path
            fill="rgba(96,96,96,1)"
            d="M0 0h75v75H0z"
            transform="rotate(224 75 75)"
          />
          <path
            fill="rgba(95,95,95,1)"
            d="M0 0h75v75H0z"
            transform="rotate(225 75 75)"
          />
          <path
            fill="rgba(94,94,94,1)"
            d="M0 0h75v75H0z"
            transform="rotate(226 75 75)"
          />
          <path
            fill="rgba(94,94,94,1)"
            d="M0 0h75v75H0z"
            transform="rotate(227 75 75)"
          />
          <path
            fill="rgba(93,93,93,1)"
            d="M0 0h75v75H0z"
            transform="rotate(228 75 75)"
          />
          <path
            fill="rgba(92,92,92,1)"
            d="M0 0h75v75H0z"
            transform="rotate(229 75 75)"
          />
          <path
            fill="rgba(92,92,92,1)"
            d="M0 0h75v75H0z"
            transform="rotate(230 75 75)"
          />
          <path
            fill="rgba(91,91,91,1)"
            d="M0 0h75v75H0z"
            transform="rotate(231 75 75)"
          />
          <path
            fill="rgba(90,90,90,1)"
            d="M0 0h75v75H0z"
            transform="rotate(232 75 75)"
          />
          <path
            fill="rgba(89,89,89,1)"
            d="M0 0h75v75H0z"
            transform="rotate(233 75 75)"
          />
          <path
            fill="rgba(89,89,89,1)"
            d="M0 0h75v75H0z"
            transform="rotate(234 75 75)"
          />
          <path
            fill="rgba(88,88,88,1)"
            d="M0 0h75v75H0z"
            transform="rotate(235 75 75)"
          />
          <path
            fill="rgba(87,87,87,1)"
            d="M0 0h75v75H0z"
            transform="rotate(236 75 75)"
          />
          <path
            fill="rgba(87,87,87,1)"
            d="M0 0h75v75H0z"
            transform="rotate(237 75 75)"
          />
          <path
            fill="rgba(86,86,86,1)"
            d="M0 0h75v75H0z"
            transform="rotate(238 75 75)"
          />
          <path
            fill="rgba(85,85,85,1)"
            d="M0 0h75v75H0z"
            transform="rotate(239 75 75)"
          />
          <path
            fill="rgba(85,85,85,1)"
            d="M0 0h75v75H0z"
            transform="rotate(240 75 75)"
          />
          <path
            fill="rgba(84,84,84,1)"
            d="M0 0h75v75H0z"
            transform="rotate(241 75 75)"
          />
          <path
            fill="rgba(83,83,83,1)"
            d="M0 0h75v75H0z"
            transform="rotate(242 75 75)"
          />
          <path
            fill="rgba(82,82,82,1)"
            d="M0 0h75v75H0z"
            transform="rotate(243 75 75)"
          />
          <path
            fill="rgba(82,82,82,1)"
            d="M0 0h75v75H0z"
            transform="rotate(244 75 75)"
          />
          <path
            fill="rgba(81,81,81,1)"
            d="M0 0h75v75H0z"
            transform="rotate(245 75 75)"
          />
          <path
            fill="rgba(80,80,80,1)"
            d="M0 0h75v75H0z"
            transform="rotate(246 75 75)"
          />
          <path
            fill="rgba(80,80,80,1)"
            d="M0 0h75v75H0z"
            transform="rotate(247 75 75)"
          />
          <path
            fill="rgba(79,79,79,1)"
            d="M0 0h75v75H0z"
            transform="rotate(248 75 75)"
          />
          <path
            fill="rgba(78,78,78,1)"
            d="M0 0h75v75H0z"
            transform="rotate(249 75 75)"
          />
          <path
            fill="rgba(77,77,77,1)"
            d="M0 0h75v75H0z"
            transform="rotate(250 75 75)"
          />
          <path
            fill="rgba(77,77,77,1)"
            d="M0 0h75v75H0z"
            transform="rotate(251 75 75)"
          />
          <path
            fill="rgba(76,76,76,1)"
            d="M0 0h75v75H0z"
            transform="rotate(252 75 75)"
          />
          <path
            fill="rgba(75,75,75,1)"
            d="M0 0h75v75H0z"
            transform="rotate(253 75 75)"
          />
          <path
            fill="rgba(75,75,75,1)"
            d="M0 0h75v75H0z"
            transform="rotate(254 75 75)"
          />
          <path
            fill="rgba(74,74,74,1)"
            d="M0 0h75v75H0z"
            transform="rotate(255 75 75)"
          />
          <path
            fill="rgba(73,73,73,1)"
            d="M0 0h75v75H0z"
            transform="rotate(256 75 75)"
          />
          <path
            fill="rgba(72,72,72,1)"
            d="M0 0h75v75H0z"
            transform="rotate(257 75 75)"
          />
          <path
            fill="rgba(72,72,72,1)"
            d="M0 0h75v75H0z"
            transform="rotate(258 75 75)"
          />
          <path
            fill="rgba(71,71,71,1)"
            d="M0 0h75v75H0z"
            transform="rotate(259 75 75)"
          />
          <path
            fill="rgba(70,70,70,1)"
            d="M0 0h75v75H0z"
            transform="rotate(260 75 75)"
          />
          <path
            fill="rgba(70,70,70,1)"
            d="M0 0h75v75H0z"
            transform="rotate(261 75 75)"
          />
          <path
            fill="rgba(69,69,69,1)"
            d="M0 0h75v75H0z"
            transform="rotate(262 75 75)"
          />
          <path
            fill="rgba(68,68,68,1)"
            d="M0 0h75v75H0z"
            transform="rotate(263 75 75)"
          />
          <path
            fill="rgba(68,68,68,1)"
            d="M0 0h75v75H0z"
            transform="rotate(264 75 75)"
          />
          <path
            fill="rgba(67,67,67,1)"
            d="M0 0h75v75H0z"
            transform="rotate(265 75 75)"
          />
          <path
            fill="rgba(66,66,66,1)"
            d="M0 0h75v75H0z"
            transform="rotate(266 75 75)"
          />
          <path
            fill="rgba(65,65,65,1)"
            d="M0 0h75v75H0z"
            transform="rotate(267 75 75)"
          />
          <path
            fill="rgba(65,65,65,1)"
            d="M0 0h75v75H0z"
            transform="rotate(268 75 75)"
          />
          <path
            fill="rgba(64,64,64,1)"
            d="M0 0h75v75H0z"
            transform="rotate(269 75 75)"
          />
          <path
            fill="rgba(63,63,63,1)"
            d="M0 0h75v75H0z"
            transform="rotate(270 75 75)"
          />
          <path
            fill="rgba(63,63,63,1)"
            d="M0 0h75v75H0z"
            transform="rotate(271 75 75)"
          />
          <path
            fill="rgba(62,62,62,1)"
            d="M0 0h75v75H0z"
            transform="rotate(272 75 75)"
          />
          <path
            fill="rgba(61,61,61,1)"
            d="M0 0h75v75H0z"
            transform="rotate(273 75 75)"
          />
          <path
            fill="rgba(60,60,60,1)"
            d="M0 0h75v75H0z"
            transform="rotate(274 75 75)"
          />
          <path
            fill="rgba(60,60,60,1)"
            d="M0 0h75v75H0z"
            transform="rotate(275 75 75)"
          />
          <path
            fill="rgba(59,59,59,1)"
            d="M0 0h75v75H0z"
            transform="rotate(276 75 75)"
          />
          <path
            fill="rgba(58,58,58,1)"
            d="M0 0h75v75H0z"
            transform="rotate(277 75 75)"
          />
          <path
            fill="rgba(58,58,58,1)"
            d="M0 0h75v75H0z"
            transform="rotate(278 75 75)"
          />
          <path
            fill="rgba(57,57,57,1)"
            d="M0 0h75v75H0z"
            transform="rotate(279 75 75)"
          />
          <path
            fill="rgba(56,56,56,1)"
            d="M0 0h75v75H0z"
            transform="rotate(280 75 75)"
          />
          <path
            fill="rgba(55,55,55,1)"
            d="M0 0h75v75H0z"
            transform="rotate(281 75 75)"
          />
          <path
            fill="rgba(55,55,55,1)"
            d="M0 0h75v75H0z"
            transform="rotate(282 75 75)"
          />
          <path
            fill="rgba(54,54,54,1)"
            d="M0 0h75v75H0z"
            transform="rotate(283 75 75)"
          />
          <path
            fill="rgba(53,53,53,1)"
            d="M0 0h75v75H0z"
            transform="rotate(284 75 75)"
          />
          <path
            fill="rgba(53,53,53,1)"
            d="M0 0h75v75H0z"
            transform="rotate(285 75 75)"
          />
          <path
            fill="rgba(52,52,52,1)"
            d="M0 0h75v75H0z"
            transform="rotate(286 75 75)"
          />
          <path
            fill="rgba(51,51,51,1)"
            d="M0 0h75v75H0z"
            transform="rotate(287 75 75)"
          />
          <path
            fill="rgba(50,50,50,1)"
            d="M0 0h75v75H0z"
            transform="rotate(288 75 75)"
          />
          <path
            fill="rgba(50,50,50,1)"
            d="M0 0h75v75H0z"
            transform="rotate(289 75 75)"
          />
          <path
            fill="rgba(49,49,49,1)"
            d="M0 0h75v75H0z"
            transform="rotate(290 75 75)"
          />
          <path
            fill="rgba(48,48,48,1)"
            d="M0 0h75v75H0z"
            transform="rotate(291 75 75)"
          />
          <path
            fill="rgba(48,48,48,1)"
            d="M0 0h75v75H0z"
            transform="rotate(292 75 75)"
          />
          <path
            fill="rgba(47,47,47,1)"
            d="M0 0h75v75H0z"
            transform="rotate(293 75 75)"
          />
          <path
            fill="rgba(46,46,46,1)"
            d="M0 0h75v75H0z"
            transform="rotate(294 75 75)"
          />
          <path
            fill="rgba(46,46,46,1)"
            d="M0 0h75v75H0z"
            transform="rotate(295 75 75)"
          />
          <path
            fill="rgba(45,45,45,1)"
            d="M0 0h75v75H0z"
            transform="rotate(296 75 75)"
          />
          <path
            fill="rgba(44,44,44,1)"
            d="M0 0h75v75H0z"
            transform="rotate(297 75 75)"
          />
          <path
            fill="rgba(43,43,43,1)"
            d="M0 0h75v75H0z"
            transform="rotate(298 75 75)"
          />
          <path
            fill="rgba(43,43,43,1)"
            d="M0 0h75v75H0z"
            transform="rotate(299 75 75)"
          />
          <path
            fill="rgba(42,42,42,1)"
            d="M0 0h75v75H0z"
            transform="rotate(300 75 75)"
          />
          <path
            fill="rgba(41,41,41,1)"
            d="M0 0h75v75H0z"
            transform="rotate(301 75 75)"
          />
          <path
            fill="rgba(41,41,41,1)"
            d="M0 0h75v75H0z"
            transform="rotate(302 75 75)"
          />
          <path
            fill="rgba(40,40,40,1)"
            d="M0 0h75v75H0z"
            transform="rotate(303 75 75)"
          />
          <path
            fill="rgba(39,39,39,1)"
            d="M0 0h75v75H0z"
            transform="rotate(304 75 75)"
          />
          <path
            fill="rgba(38,38,38,1)"
            d="M0 0h75v75H0z"
            transform="rotate(305 75 75)"
          />
          <path
            fill="rgba(38,38,38,1)"
            d="M0 0h75v75H0z"
            transform="rotate(306 75 75)"
          />
          <path
            fill="rgba(37,37,37,1)"
            d="M0 0h75v75H0z"
            transform="rotate(307 75 75)"
          />
          <path
            fill="rgba(36,36,36,1)"
            d="M0 0h75v75H0z"
            transform="rotate(308 75 75)"
          />
          <path
            fill="rgba(36,36,36,1)"
            d="M0 0h75v75H0z"
            transform="rotate(309 75 75)"
          />
          <path
            fill="rgba(35,35,35,1)"
            d="M0 0h75v75H0z"
            transform="rotate(310 75 75)"
          />
          <path
            fill="rgba(34,34,34,1)"
            d="M0 0h75v75H0z"
            transform="rotate(311 75 75)"
          />
          <path
            fill="rgba(33,33,33,1)"
            d="M0 0h75v75H0z"
            transform="rotate(312 75 75)"
          />
          <path
            fill="rgba(33,33,33,1)"
            d="M0 0h75v75H0z"
            transform="rotate(313 75 75)"
          />
          <path
            fill="rgba(32,32,32,1)"
            d="M0 0h75v75H0z"
            transform="rotate(314 75 75)"
          />
          <path
            fill="rgba(31,31,31,1)"
            d="M0 0h75v75H0z"
            transform="rotate(315 75 75)"
          />
          <path
            fill="rgba(31,31,31,1)"
            d="M0 0h75v75H0z"
            transform="rotate(316 75 75)"
          />
          <path
            fill="rgba(30,30,30,1)"
            d="M0 0h75v75H0z"
            transform="rotate(317 75 75)"
          />
          <path
            fill="rgba(29,29,29,1)"
            d="M0 0h75v75H0z"
            transform="rotate(318 75 75)"
          />
          <path
            fill="rgba(29,29,29,1)"
            d="M0 0h75v75H0z"
            transform="rotate(319 75 75)"
          />
          <path
            fill="rgba(28,28,28,1)"
            d="M0 0h75v75H0z"
            transform="rotate(320 75 75)"
          />
          <path
            fill="rgba(27,27,27,1)"
            d="M0 0h75v75H0z"
            transform="rotate(321 75 75)"
          />
          <path
            fill="rgba(26,26,26,1)"
            d="M0 0h75v75H0z"
            transform="rotate(322 75 75)"
          />
          <path
            fill="rgba(26,26,26,1)"
            d="M0 0h75v75H0z"
            transform="rotate(323 75 75)"
          />
          <path
            fill="rgba(25,25,25,1)"
            d="M0 0h75v75H0z"
            transform="rotate(324 75 75)"
          />
          <path
            fill="rgba(24,24,24,1)"
            d="M0 0h75v75H0z"
            transform="rotate(325 75 75)"
          />
          <path
            fill="rgba(24,24,24,1)"
            d="M0 0h75v75H0z"
            transform="rotate(326 75 75)"
          />
          <path
            fill="rgba(23,23,23,1)"
            d="M0 0h75v75H0z"
            transform="rotate(327 75 75)"
          />
          <path
            fill="rgba(22,22,22,1)"
            d="M0 0h75v75H0z"
            transform="rotate(328 75 75)"
          />
          <path
            fill="rgba(21,21,21,1)"
            d="M0 0h75v75H0z"
            transform="rotate(329 75 75)"
          />
          <path
            fill="rgba(21,21,21,1)"
            d="M0 0h75v75H0z"
            transform="rotate(330 75 75)"
          />
          <path
            fill="rgba(20,20,20,1)"
            d="M0 0h75v75H0z"
            transform="rotate(331 75 75)"
          />
          <path
            fill="rgba(19,19,19,1)"
            d="M0 0h75v75H0z"
            transform="rotate(332 75 75)"
          />
          <path
            fill="rgba(19,19,19,1)"
            d="M0 0h75v75H0z"
            transform="rotate(333 75 75)"
          />
          <path
            fill="rgba(18,18,18,1)"
            d="M0 0h75v75H0z"
            transform="rotate(334 75 75)"
          />
          <path
            fill="rgba(17,17,17,1)"
            d="M0 0h75v75H0z"
            transform="rotate(335 75 75)"
          />
          <path
            fill="rgba(16,16,16,1)"
            d="M0 0h75v75H0z"
            transform="rotate(336 75 75)"
          />
          <path
            fill="rgba(16,16,16,1)"
            d="M0 0h75v75H0z"
            transform="rotate(337 75 75)"
          />
          <path
            fill="rgba(15,15,15,1)"
            d="M0 0h75v75H0z"
            transform="rotate(338 75 75)"
          />
          <path
            fill="rgba(14,14,14,1)"
            d="M0 0h75v75H0z"
            transform="rotate(339 75 75)"
          />
          <path
            fill="rgba(14,14,14,1)"
            d="M0 0h75v75H0z"
            transform="rotate(340 75 75)"
          />
          <path
            fill="rgba(13,13,13,1)"
            d="M0 0h75v75H0z"
            transform="rotate(341 75 75)"
          />
          <path
            fill="rgba(12,12,12,1)"
            d="M0 0h75v75H0z"
            transform="rotate(342 75 75)"
          />
          <path
            fill="rgba(12,12,12,1)"
            d="M0 0h75v75H0z"
            transform="rotate(343 75 75)"
          />
          <path
            fill="rgba(11,11,11,1)"
            d="M0 0h75v75H0z"
            transform="rotate(344 75 75)"
          />
          <path
            fill="rgba(10,10,10,1)"
            d="M0 0h75v75H0z"
            transform="rotate(345 75 75)"
          />
          <path
            fill="rgba(9,9,9,1)"
            d="M0 0h75v75H0z"
            transform="rotate(346 75 75)"
          />
          <path
            fill="rgba(9,9,9,1)"
            d="M0 0h75v75H0z"
            transform="rotate(347 75 75)"
          />
          <path
            fill="rgba(8,8,8,1)"
            d="M0 0h75v75H0z"
            transform="rotate(348 75 75)"
          />
          <path
            fill="rgba(7,7,7,1)"
            d="M0 0h75v75H0z"
            transform="rotate(349 75 75)"
          />
          <path
            fill="rgba(7,7,7,1)"
            d="M0 0h75v75H0z"
            transform="rotate(350 75 75)"
          />
          <path
            fill="rgba(6,6,6,1)"
            d="M0 0h75v75H0z"
            transform="rotate(351 75 75)"
          />
          <path
            fill="rgba(5,5,5,1)"
            d="M0 0h75v75H0z"
            transform="rotate(352 75 75)"
          />
          <path
            fill="rgba(4,4,4,1)"
            d="M0 0h75v75H0z"
            transform="rotate(353 75 75)"
          />
          <path
            fill="rgba(4,4,4,1)"
            d="M0 0h75v75H0z"
            transform="rotate(354 75 75)"
          />
          <path
            fill="rgba(3,3,3,1)"
            d="M0 0h75v75H0z"
            transform="rotate(355 75 75)"
          />
          <path
            fill="rgba(2,2,2,1)"
            d="M0 0h75v75H0z"
            transform="rotate(356 75 75)"
          />
          <path
            fill="rgba(2,2,2,1)"
            d="M0 0h75v75H0z"
            transform="rotate(357 75 75)"
          />
          <path
            fill="rgba(1,1,1,1)"
            d="M0 0h75v75H0z"
            transform="rotate(358 75 75)"
          />
          <path
            fill="rgba(0,0,0,1)"
            d="M0 0h75v75H0z"
            transform="rotate(359 75 75)"
          />
        </g>
      </mask>
    </defs>
    <circle
      cx="75"
      cy="75"
      r="75"
      fill="#dadada"
      mask="url(#b)"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BigLoader'
});
</script>
